@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Body styling */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Spec Card container */
.spec-card {
  width: auto;
  height: auto;
  background: #fff; /* White background for the card */
  border-radius: 10px; /* Rounded corners for card */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for better depth */
  border: 1px solid #e0e0e0; /* Light border for definition */
}

/* Table styling */
.spec-table {
  width: 100%;
  border-collapse: collapse; /* Remove gaps between table cells */
  font-size: 13px; /* Default font size */
  font-family: 'Roboto', sans-serif; /* Ensure Roboto is applied to the table */
  table-layout: fixed; /* Equal width for columns */
  word-wrap: break-word; /* Allow words to break for long text */
}

/* Header row styling */
.spec-table thead th {
  text-align: center; /* Center the header text */
  font-size: 13px; /* Larger font size for the header */
  font-weight: 500; /* Medium weight for header text */
  border-bottom: 2px solid #ddd; /* Soft gray border beneath header */
  color: #333; /* Dark text for visibility */
  word-wrap: break-word; /* Allow words to break for long header text */
  padding: 5px; /* Space around header text */
}

/* Table cell styling */
.spec-table tbody td {
  padding: 5px; /* Add padding for better readability */
  font-size: 13px; /* Font size for table cells */
  color: #555; /* Lighter color for content */
  border-bottom: 1px solid #e0e0e0; /* Light separator between rows */
  word-wrap: break-word; /* Break long words or content */
  text-align: left; /* Align text to the left */
}

/* First column cell styling (label column) */
.spec-table td:first-child {
  font-weight: 600; /* Bold for the label column */
  color: #444; /* Darker color for labels */
  font-family: 'Roboto', sans-serif;
}

/* Hover effect for table rows */
.spec-table tbody tr:hover {
  background-color: #f9f9f9; /* Subtle background change on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Remove bottom border on the last row */
.spec-table tbody tr:last-child td {
  border-bottom: none;
}

/* Even rows background color */
.spec-table tbody tr:nth-child(even) {
  background-color: #fff; /* White background for even rows */
}

/* Odd rows background color */
.spec-table tbody tr:nth-child(odd) {
  background-color: #fafafa; /* Slightly gray background for odd rows */
}

/* Responsive Design: Ensure specs panel and card adjust on smaller screens */
@media (max-width: 768px) {
  /* Adjust table font size for smaller screens */
  .spec-table {
    font-size: 14px; /* Smaller font size for mobile */
  }

  .spec-table td, .spec-table th {
    padding: 8px; /* Smaller padding for mobile */
  }

  .spec-table td:first-child {
    font-size: 14px; /* Adjust font size for label column */
  }

  .spec-table thead th {
    font-size: 16px; /* Adjust font size for headers */
  }
}
