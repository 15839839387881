@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&family=Poppins:wght@600&display=swap');

body.no-scroll {
  overflow: hidden;
}

/* Product Grid Container */
.product-grid-container {
  padding: 10px;
}

/* Sort Controls */
.sort-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  user-select: none;
}


.sort-icon-btn,.sort-toggle-btn {
  background-color: transparent; /* Soft pastel pink */
  color: #779ECB; /* Neutral text color */
  border: none;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bolder;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.sort-toggle-btn{
  margin-right: 30px;
  font-size: 14px;
  color: #779ECB;
}

.sort-icon-btn:hover {
  background-color: #9a596e; /* Slightly darker pastel pink */
}

.sort-icon {
  font-size: 14px;
  color: #779ECB;
}

/* Dropdown Styling for Desktop */
.dropdown-select {
  padding: 6px 10px;
  font-size: 14px;
  border: 2px solid #ccc;
  border-radius: 6px;
  background-color: white;
  transition: border-color 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-select:hover {
  border-color: #aaa;
}


.sort-toggle-btn:hover, .sort-icon-btn:hover{
  background-color: #6e98df; /* Slightly darker pastel blue */
  color: white;
}

.arrow-icon {
  font-size: 18px;
  color: #779ECB;
}
/* Bottom Sheet Overlay */
/* Bottom Sheet */
.bottom-sheet-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
}

.bottom-sheet {
  width: 100%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  touch-action: none; /* ✅ Prevents touch scrolling */
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Close Button */
.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

/* Sorting List */
.bottom-sheet h3 {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

/* Bottom Sheet Sorting List */
.bottom-sheet ul {
  list-style: none;
  padding: 0;
}

.bottom-sheet ul li {
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  transition: background 0.2s ease-in-out;
}

/* Ensure only the selected item is highlighted */
.bottom-sheet ul li.selected {
  background:  lightgray; /* Soft pastel pink */
  color: white;
}

/* Prevent hover effect from affecting the selected item */
.bottom-sheet ul li.selected:hover {
  background: #A5C3F6; /* Keep same color */
}

.bottom-sheet .max-price-slider {
  touch-action: none; /* ✅ Prevents touch scrolling */
}

/* Max Price Control */
.max-price-control {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-radius: 6px;
}

/* Max Price Label */
.max-price-label {
  font-size: 14px;
  font-weight: bold;
  color: #6B7280;
}

/* Max Price Slider */
.max-price-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 200px;
  height: 8px;
  border-radius: 10px;
  background: linear-gradient(90deg, #e3e7eb, #60799f); /* Gradient pastel colors */
  outline: none;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}

/* Slider Thumb */
.max-price-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 3px solid #A5C3F6;
  box-shadow: 0 0 10px rgba(165, 195, 246, 0.6);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.max-price-slider::-webkit-slider-thumb:hover {
  background: #A5C3F6;
  box-shadow: 0 0 15px rgba(165, 195, 246, 0.9);
}

/* Slider Focus */
.max-price-slider:focus {
  outline: none;
}

/* Max Price Input */
.max-price-input {
  font-size: 14px;
  width: 80px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 6px;
}

.max-price-input:focus {
  border: none;
  outline: none;
}

/* Results Info Section */
.results-info {
  font-size: 14px;
  font-weight: bolder;
  color: #555;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #ddd;
  user-select: none;
}

/* Copy Link Button */
.copy-link-btn {
  background-color: #A8D5BA;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-link-btn .copy-icon {
  margin-right: 4px;
  font-size: 16px;
}

.copy-link-btn:hover {
  background-color: #81CBB9;
}

.copy-link-btn.copied {
  background-color: #5982b1;
  color: white;
}

/* Grid Layout */
.product-grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 10px;
  grid-auto-rows: minmax(455px, auto);
  padding: 10px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .sort-controls {
    display: flex;
    flex-direction: row; /* Keep items in a row */
    justify-content: flex-start;
    width: 100%;
  }
  .max-price-slider{
    margin-top: 30px;
    width:50%;
  }
  .dropdown-select {
    display: none; /* Hide dropdown on mobile */
  }

  .sort-icon-btn {
    display: flex;
  }

  .product-grid {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    gap: 10px;
  }

  .results-info {
    font-size: 12px; /* Slightly smaller font size for mobile */
    display: flex;
    flex-direction: column;
  }

  .results-info span {
    margin-top: 5px; /* Add some space between lines */
  }
}
