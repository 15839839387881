/* Home Button */
.home-btn {
  background-color: #A8D5BA; /* Soft pastel green background */
  color: white; /* White text color */
  font-family: 'Poppins', sans-serif;
  font-size: 16px; /* Font size for the button text */
  padding: 10px 20px; /* Padding around the button */
  margin:20px;
  border: none;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto; /* Adjust width according to content */
  transition: background-color 0.3s ease, color 0.3s ease;
  user-select: none;
}

/* Home Icon in the Button */
.home-btn .home-icon {
  margin-right: 8px; /* Space between the icon and text */
  font-size: 18px; /* Icon size */
}

/* Hover effect for Home Button */
.home-btn:hover {
  background-color: #81CBB9; /* Slightly darker mint for hover */
  color: white; /* Keep text white on hover */
}

/* After clicking (feedback state) */
.home-btn:active {
  background-color: #4CAF50; /* Green background for click */
}

  