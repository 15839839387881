/* Base Styles */
body {
  font-family: "Calibri", sans-serif;
  margin: 0;
  padding: 0;
}

/* App Layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures it doesn't force full height */
}

/* Main content should take up most of the space */
.main-content {
  flex-grow: 1; /* Expands to fill available space */
  overflow-y: auto; /* Allows scrolling if content overflows */
}

/* Disclaimer Section */
.disclaimer-section {
  width:100%;
  flex-shrink: 0; /* Prevents it from growing */
  margin: 30px auto 20px auto; /* Space from content */
  padding: 15px;
  font-size: 10px; /* Smallest readable size */
  line-height: 1.4;
  color: #666;
  background: #f8f9fa;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Disclaimer Headings */
.disclaimer-section h2 {
  font-size: 12px;
  margin-bottom: 5px;
}

.disclaimer-section h3 {
  font-size: 11px;
  margin-top: 10px;
}

/* Disclaimer Links */
.disclaimer-section a {
  color: blue;
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .disclaimer-section {
    font-size: 9px; /* Smaller font on mobile */
    max-width: 95%;
    padding: 10px;
  }
  
  .disclaimer-section h2, 
  .disclaimer-section h3 {
    font-size: 10px;
  }
}
