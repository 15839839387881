/* Base Styles */
.selection-panel {
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; /* Ensure dropdown is not clipped */
  width: 100%;
}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin-top: 30px;
  position: relative; /* Ensure the suggestions list is positioned relative to this container */
}

.search-input {
  width: 100%;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border: 2px solid #ccc;
  border-radius: 6px;
  background-color: white;
  padding: 2px 0px 4px 2px; /* Add left padding to make space for the icon */
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  caret-color: transparent; /* Prevent the blinking cursor */
  position: relative;
}

.search-input:hover {
  background-color: white; /* Keep the background white on hover */
}

.search-input:focus {
  outline: none;
  background-color: white; /* Keep the background white on focus */
}

.icon-wrapper {
  position: relative;
  transform: translateY(-85%);
  align-self: flex-end;
  margin-bottom: 5px;
}

.icon-grey {
  color: grey;
}

/* Desktop Dropdown Styles */
.suggestions-list {
  list-style-type: none;
  position: absolute; /* Ensure the list is absolutely positioned */
  top: calc(100% - 50px); /* Place directly below the search input */
  left: 0;
  width: 100%; /* Match the input's width */
  padding: 0;
  border-radius: 8px;
  border: 1px solid #ddd;
  overflow-y: auto;
  overflow-x: hidden;
  height: 50vh; /* Set a max-height to prevent excessive scrolling */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure the suggestions list is on top */
  animation: fadeIn 0.3s forwards;
  scroll-behavior: smooth; /* Smooth scrolling for desktop */

  /* Custom scrollbar */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(221, 230, 236) #f1f1f1; /* Thumb and track color */
}

.suggestions-list::-webkit-scrollbar {
  width: 8px;
}

.suggestions-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

.suggestions-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #f1f1f1;
  transition: background-color 0.2s ease-in-out;
}

.suggestions-list li {
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.1s;
}

.suggestions-list li:hover {
  background-color: #e3f2fd;
  transform: translateX(5px);
}

/* Mobile Bottom Sheet */
.mobile-bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 5px;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 90%;
  height: 90%; /* Fixed height at 90% of the viewport */
  min-height: 25%;
  overflow-y: auto;
  padding: 8px;
  width: auto;
  animation: slideIn 0.3s ease-out;
}

/* Ensure content inside the bottom sheet can scroll properly */
.bottom-sheet-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.bottom-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
}

.bottom-sheet-header button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #124580;
  padding: 2px 2px;
}

.bottom-sheet-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-right: 5px;
}

.bottom-sheet-item {
  padding: 6px;
  background-color: rgb(232, 236, 237);
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
}

.bottom-sheet-item:hover {
  background-color: #d6e9f9;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Hide desktop dropdown on mobile */
@media (max-width: 768px) {
  .suggestions-list {
    display: none;
  }
  .search-container {
    width: 90%;
  }
  .search-input {
    height: 30px;
    font-size: 16px;
  }
}

/* Hide mobile bottom sheet on desktop */
@media (min-width: 769px) {
  .mobile-bottom-sheet {
    display: none;
  }
}
