/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

/* Card Container for responsive layout */
.card-container {
    background: #fff;
    width:100%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, height 0.3s ease; /* Smooth transitions for expansion */
    position: relative;
    user-select: none;
  }
.card-container.expanded {
    grid-row: span 3; /* The expanded card will span 3 rows */
    height: 500px; /* Adjust height to give a taller card */
    z-index: 1;
 }

/* Product Number Section with Left Justify and Right Justify Copy Link Button */
/* Product Number Section with Left Justify and Right Justify Copy Link Button */
/* Product Number Section with Left Justify and Right Justify Copy Link Button */
.product-number-band {
  background-color: #f9f9f9; /* Light beige background color */
  color: #5e5b5b; /* Dark gray text color */
  align-items: center; /* Vertically center the content */
  padding: 8px 15px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border-radius: 10px 10px 0 0; /* Rounded top corners */
  font-weight: bold;
  margin-bottom: 10px;
}

.offer-amount-link {
  color: #007bff; /* Blue color like typical links */
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.offer-amount-link:hover {
  text-decoration: none;
  color: #0056b3; /* Darker blue on hover */
}

/* If there's no valid link, disable interaction */
.disabled-link {
  pointer-events: none;
  color: #888; /* Greyed out */
  text-decoration: none;
}


/* Header Section */
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
}

.title {
  font-size: 16px;
  color: #3375b6; /* blue color */
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.subtitle-price-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1px;
}

.subtitle {
  font-size: 12px;
  color: #725856; /* light brown */
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.price {
  font-size:17px;
  font-weight: bold;
  color: #8f7d26dd; /* golden color */
  margin: 0;
  margin-left: auto;
}

/* Image Container */
.image-container {
  width: 100%; /* Ensures it spans full width */
  display: flex; /* Centers the image */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents any overflow */
}

/* Image Styling */
.image {
  width: 100%; /* Makes sure the image resizes with the container */
  height: auto; /* Keeps aspect ratio */
  max-height: 40vh; /* Makes it adaptive to screen height */
  object-fit: contain; /* Prevents cropping */
  border-radius: 15px;
}

/* Info Section */
.info-container {
  text-align: center;
}

.info-text {
  color: #7d9c9c; /* light gray */
  margin-top: -4px;
}

.info-text.seating {
  color: #1a6b7e; /* blue color */
  font-family: 'Calibri', monospace;
  font-weight: bolder;
  font-size: 13px;
  
}

.info-text.style {
  color: #232f7e; /* deep blue */
  font-family: 'Calibri', monospace;
  font-weight: bolder;
  font-size: 13px;
}

.info-text.drive-train {
  color: #d07340; /* deep purple */
  font-family: 'Calibri', monospace;
  font-weight: bolder;
  font-size: 13px;
}

.info-text.dimensions {
  color: #596372; /* dark gray */
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  margin-top: 10px;
}

.additional-info {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  font-size: 1px;
  font-weight: 500;
  color: #165f81; /* darker blue */
  font-family: 'calibri', monospace;
  letter-spacing: 0.5px;
}

/* Offers Container: Side by side display */
.offers-container {
  display: flex;
  justify-content: space-between; /* Side by side for lease and finance offers */
  text-align: left;
  margin-top: 1px;
  font-size: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
}

/* Individual Offer Details */
.offer-details {
  font-size: 10px;
  color: #333;
  display: flex;
  flex-direction: column;
}

/* Offer Heading */
.offer-details h4 {
  font-size: 10px;
  color: #8f7d26dd;
  margin: 0 0 5px 0;
  font-weight: 600;
}

/* Lease and Finance Amount on the same line */
.offer-details span {
  font-size: 10px;
  color: #555;
  display: inline-block;
}

.disclaimer {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
  font-style: italic;
}


/* Additional Finance APR */
.finance-amount {
  margin-top: 2px;
  color: #555;
  font-size: 12px;
}

/* Calculated Finance Amount */
.calculated-finance {
  font-size: 10px;
  color: #555;
  font-style: italic;
  margin-top: 5px;
}


/* Gauge Container */
.gauge-container {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  margin-left: -15px;
}

.gauge {
  width: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gauge-container .gauge-label {
  font-size: 12px;
  font-family: 'Calibri', monospace;
  font-weight: bolder;
  color: #6f4f69; /* dark pink */
  letter-spacing: 0.5px;
  margin-top: 5px;
}

.gauge-container .gauge-unit {
  font-size: 10px;
  color: #7a7a7a;
  font-weight: 500;
  margin-top: -12px;
  letter-spacing: 0.5px;
}

.custom-progressbar .CircularProgressbar-text {
  font-family: 'Roboto', monospace;
  fill: #444;
  dominant-baseline: middle;
  text-anchor: middle;
}

/* Expand/Collapse Button */
.expand-collapse-btn {
  background-color: #FFE5B4; 
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 10px 10px;
  margin: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Product Page Button */
.similar-cars-btn {
  background-color: #d0e7c6; 
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  padding: 10px 10px;
  margin: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}


/* Specs Panel */
.specs-panel {
    background-color: #f9f9f9;
    border-top: 1px solid #e0e0e0; /* Thin border */
    text-align: left;
    font-size: 14px;
    color: #555;
    margin-top: 10px;
    border-radius: 10px;
    overflow-x: auto; /* Allow horizontal scroll if the table overflows */
    width: 100%; /* Ensure it takes the full width available */
    box-sizing: border-box; /* Include padding in width calculations */
  }
  

/* Expand/Collapse Panel */
.specs-panel p {
  margin-bottom: 10px;
  color: #777;
}

/* Button Container */
.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 1px;
}

/* Hover effects for buttons */
button:hover {
  transform: scale(1.05);
}


@media (max-width: 768px) {
  .card-container  {
    width: 100%; /* Stack the cards vertically on small screens */
  }

}
